body{
   
}
body .bg-primary{
    background-color: #fab702 !important;
}
body .bg-primaryLight{
    background-color: #fff7e0 !important;
}
body .text-primary{
    color: #fab702 !important;
}
body .border{
    border-color: #fab702 !important;
}
.loginBG{
    left: 0;
   right: 0;
   height: 100vh;
   width: 100%;
   z-index: -1  ;
   display: flex;
  
}
.loginBox{
    box-shadow: 0 0 25px 0 #61470040;
    padding: 30px 36px;
    min-width: 320px;
}
.loginBox input{
    background-color: transparent;
    border-color: #000;
}
.loginBox .form-label{
    margin-bottom: 2px;
}
.loginBox h2{
    font-size: 24px;
}
.multipleForm .form-label{
  font-weight: 600;
}
.multipleForm-heading{
    font-size: 22px;
}
.multipleForm-subHeading{
    font-size: 16px;
}
.multipleForm-table thead th{
    font-size: 12px;
    text-align: center;
}
.multipleForm-table thead th:nth-child(2){
    text-align: left;
}
.multipleForm-table tbody td{
    text-align: center;
}
.multipleForm-table tbody td:nth-child(2){
    text-align: left;
}



/* ========admin  */
.step3-documentList li{
    border-bottom: 1px dashed #ddd;
}

.sidebarList {
    background-color: #000;
}
.sidebarList .menu-item{
    border-bottom: 1px dashed #292828;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}
.sidebarList .menu-item .badge{
    background-color: #292828;
    border-radius: 20px;
    font-size: 10px;
    height: 20px;
    width: 20px;

}
.sidebarList i{
    width: 20px;
    
}
.Admin-addUserBox,
.Admin-uploadUserBox{
    background: #efefef;
    padding: 20px;
    border-radius: 10px;
}
.userList-page .userList{
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
}
.MenuTopbar ul li.active a{
    color: #fab702;
}

/* Change the background and border color when checked */
.form-check-input {
    border: 1px solid rgba(0, 0, 0, .25);
    height: 18px;
    width: 18px;
}

.form-check-input:checked {
    background-color: rgba(250, 183, 2, .5);
    border-color: rgba(250, 183, 2, 0);
}

.form-check-input:focus {
    border-color: rgba(250, 183, 2, .5);
    box-shadow: 0 0 0 0.25rem rgba(250, 183, 2, 0.1);
}

.form-check-input:hover {
    border-color: rgba(250, 183, 2, .5);
}

.form-control:focus {
    border-color: rgba(250, 183, 2, .3);
    box-shadow: 0 0 0 0.25rem rgba(250, 183, 2, 0.1);
}

@media only screen and (min-width: 1300px) {
    .container{
        max-width: 1300px;
    }
  }


/* Apply CSS to make the sidebar fixed and non-scrollable */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; /* Adjust the width to your preference */
    background-color: #333;
    color: #fff;
    overflow-y: scroll;
  }
  
  /* Style the content to appear on the right of the sidebar */
  .content {
    margin-left: 0px; /* Adjust this value to match the width of the sidebar */
    padding: 20px; /* Add padding to create space between the sidebar and content */
  }
  

